export enum Routes {
  _error404 = '/404',
  _error500 = '/500',

  home = '/',
  qossmic = '/qossmic',
  qossmic_women_in_tech = '/qossmic/women-in-tech',

  jobs = '/jobs',
  jobs_qossmic_backstage_tour = '/jobs/qossmic-backstage-tour',
  jobs_php_symfony_developer_qossmic = '/jobs/php-symfony-developer-qossmic',
  //jobs_sales_representative_qossmic = '/jobs/sales-representative-qossmic',
  jobs_senior_account_executive_qossmic = '/jobs/senior-account-executive-qossmic',
  jobs_php_symfony_developer_provolution = '/jobs/php-symfony-entwicklerin-bei-qossmic-application-development',
  jobs_frontend_developer_provolution = '/jobs/frontend-javascript-entwicklerin-bei-qossmic-application-development',
  //jobs_uxui_provolution = '/jobs/uxui-design-bei-qossmic-application-development',
  //jobs_po_provolution = '/jobs/product-owner-bei-qossmic-application-development',
  //jobs_student_provolution = '/jobs/werkstudentin-bei-qossmic-application-development',

  community = '/community',
  community_gitlab_coordinators = '/community/supercharge-your-mrs-with-gitlabs-coordinators',
  community_sshmagic = '/community/ssh-magic-fun-with-ssh',
  community_symfony_enterprise = '/community/an-introduction-to-a-symfony-enterprise-cms',
  community_e2e = '/community/e2e-tests-mit-cypress',
  community_symfony53 = '/community/whats-new-in-symfony-5.3',
  community_symfony51 = '/community/whats-new-in-symfony-5.1-getting-started-with-mutation-testing',
  community_symfony_runtime = '/community/symfony-runtime-component-a-first-look',
  community_symfony_cartogrpahing = '/community/cartographing-symfony',
  community_php81_symfony = '/community/php81-symfony',
  community_sulu = '/community/sulu-symfony-cms',
  community_encryption = '/community/integration-moderner-verschluesselung-mit-symfony',
  community_react_usergroup_cologne = '/community/react-usergroup-cologne-spring-edition',
  community_react_usergroup_cologne2 = '/community/react-usergroup-cologne-flow-edition',
  community_women_in_tech = '/community/meetup-quereinstieg-frauen-in-die-it',
  community_girlsday_2023 = '/community/girls-day-2023',

  services = '/services',
  services_loesungen_jacques = '/services/loesungen/jacques-wein-depot-ecommerce-mit-symfony',
  services_loesungen_lekker = '/services/loesungen/lekker-portalmodernisierung-mit-symfony-expertise',
  services_loesungen_teamhero = '/services/loesungen/teamhero-sparring-weiterentwicklung-symfony-webanwendung',
  services_loesungen_tonies = '/services/loesungen/tonies-internationalisierung-des-shop-mit-symfony',
  services_loesungen_zmg = '/services/loesungen/zmg-plattform-fuer-die-marktforschung-mit-symfony',
  services_reactjs = '/services/mit-react-javascript-web-applikationen-entwickeln',
  services_reactjs_agentur = '/services/react-js-agentur-aus-koeln',
  services_symfony_framework = '/services/mit-symfony-framework-web-applikationen-entwickeln',
  services_symfony_agentur = '/services/php-symfony-agentur-aus-koeln',
  services_prototyp = '/services/prototyp-als-entscheidungsbeschleuniger',
  services_zusammenarbeit_extern = '/services/zusammenarbeit-mit-externen-it-dienstleistern',
  services_frontend_react = '/services/react-frontend',
  services_symfony_migration = '/services/symfony-migration',
  services_symfony_trainings = '/services/symfony-trainings',
  services_react_trainings = '/services/react-trainings',
  services_sulu_trainings = '/services/sulu-trainings',
  services_symfony_upgrade = '/services/symfony-upgrade',
  services_itDienstleisterSoftwareentwicklung = '/services/it-dienstleister-softwareentwicklung',

  academy = '/academy',
  contact = '/kontakt',
  imprint = '/impressum',
  privacy = '/datenschutzerklaerung',
}
