import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import { getOrigin } from '@Components/helper/urlHelper'

export interface MetaDataInfo {
  title?: string
  canonicalUrl?: string | false
  metaDescription?: string
  metaAuthor?: string
  ogLocale?: string
  ogType?: string
  ogTitle?: string
  ogDescription?: string
  ogUrl?: string | false
  ogImage?: string
  ogImageWidth?: number
  ogImageHeight?: number
  fbAppId?: string
  twitterCard?: string
  twitterDescription?: string
  twitterTitle?: string
  twitterSite?: string
  twitterImage?: string
}

export const defaultMetaData: MetaDataInfo = {
  title: 'QOSSMIC',
  canonicalUrl: 'https://www.qossmic.com',
  metaDescription:
    'QOSSMIC bietet Services für Ihre Enterprise Anwendung rund um Symfony und React, Architektur- und Infrastruktur-Beratung, Code Reviews, Audits, Trainings und Entwicklungsleistung',
  metaAuthor: 'QOSSMIC',
  ogLocale: 'de_DE',
  ogType: 'website',
  fbAppId: '',
  ogImage: '/share/qossmic-share.png',
  ogImageWidth: 2400,
  ogImageHeight: 1260,
  twitterCard: 'summary_large_image',
  twitterSite: '@QOSSMIC',
  twitterImage: '/share/qossmic-share.png',
}

const enhanceImageUrl = (url?: string): string => {
  const slash = url?.startsWith('/') ? '' : '/'
  return getOrigin() + slash + url
}

const MetaData = (props: MetaDataInfo): React.ReactElement => {
  const values = { ...defaultMetaData, ...props }
  const router = useRouter()

  const origin = getOrigin()

  if (props.canonicalUrl) {
    // add the manually added canonical-url
    values.canonicalUrl = origin + props.canonicalUrl
  } else if (typeof props.canonicalUrl === 'undefined') {
    // set the canonical url to the router's pathname as default if nothing is provided
    values.canonicalUrl = origin + router?.pathname
  }
  values.ogUrl = values.canonicalUrl

  values.ogImage = enhanceImageUrl(values.ogImage)
  values.ogTitle = props.ogTitle ? props.ogTitle : values.title
  values.ogDescription = props.metaDescription
    ? props.metaDescription
    : values.metaDescription
  values.twitterTitle = props.twitterTitle ? props.twitterTitle : values.title
  values.twitterImage = props.twitterImage
    ? enhanceImageUrl(props.twitterImage)
    : values.ogImage
  values.twitterDescription = props.twitterDescription
    ? props.twitterDescription
    : values.metaDescription

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {values.canonicalUrl && (
        <>
          <meta name="robots" content="follow, index" />
          <link rel="canonical" href={values.canonicalUrl} />
        </>
      )}
      {values.canonicalUrl === false && (
        <meta name="robots" content="noindex" />
      )}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${origin}/favicons/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${origin}/favicons/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${origin}/favicons/favicon-16x16.png`}
      />
      <link
        rel="mask-icon"
        href={`${origin}/favicons/safari-pinned-tab.svg`}
        color="#5bbad5"
      />
      <link rel="shortcut icon" href={`${origin}/favicons/favicon.ico`} />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta
        name="msapplication-config"
        content={`${origin}/favicons/browserconfig.xml`}
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <link rel="manifest" href={`${origin}/manifest/manifest.json`} />
      <meta name="description" content={values.metaDescription} />
      <meta name="author" content={values.metaAuthor} />
      <meta property="og:locale" content={values.ogLocale} />
      <meta property="og:type" content={values.ogType} />
      <meta property="og:title" content={values.ogTitle} />
      <meta property="og:description" content={values.ogDescription} />
      {values.ogUrl && <meta property="og:url" content={values.ogUrl} />}
      <meta property="fb:app_id" content={values.fbAppId} />
      <meta
        property="og:image:width"
        content={values.ogImageWidth?.toString()}
      />
      <meta
        property="og:image:height"
        content={values.ogImageHeight?.toString()}
      />
      <meta property="og:image" content={values.ogImage} />
      <meta name="twitter:card" content={values.twitterCard} />
      <meta name="twitter:description" content={values.twitterDescription} />
      <meta name="twitter:title" content={values.twitterTitle} />
      <meta name="twitter:site" content={values.twitterSite} />
      <meta name="twitter:image" content={values.twitterImage} />
    </Head>
  )
}

export { MetaData }
